<template>
  <a-carousel :autoplay="false" :dots="false">
    <div>
      <a-image :src="'/images/hotel_index_' + language + '.jpg'" :preview="false"/>
    </div>
  </a-carousel>
  <div class="hotel-search-index">
    <hotel-search @searchGo="searchGo"></hotel-search>
  </div>
  <div class="top-hotels">
    <a-radio-group v-model:value="selectTopCity" button-style="solid" v-for="(city,i) in topHotels" v-bind:key="i">
      <a-radio-button :value="city.city_code">{{ isZHCN ? city.city_name_cn : city.city_name_en }}</a-radio-button>
    </a-radio-group>
    <template v-for="(city,i) in topHotels" v-bind:key="i">
      <div class="hotels" v-if="city.city_code === selectTopCity">
        <a-row :gutter="[16,16]">
          <template v-for="(hotel, j) in city.list" v-bind:key="j">
            <a-col :span="8">
              <div class="hotel"
                   :style="{ backgroundImage: 'url(\'' + getFullImageUrl(hotel.image_url, false) + '\')'}">
                <a @click="goDetail(hotel.hotel_id);">
                  <div class="hotel-text">
                    <a-space direction="vertical">
                      <span>
                        <StarFilled class="starColor" v-for="i in parseInt(hotel.hotel_star)" v-bind:key="i"/>
                      </span>
                      <span v-if="!isEmpty(hotel.hotel_name_cn) && isZHCN">{{ hotel.hotel_name_cn }}</span>
                      <span>{{ hotel.hotel_name_en }}</span>
                    </a-space>
                  </div>
                </a>
              </div>
            </a-col>
          </template>
        </a-row>
      </div>
    </template>
  </div>
</template>

<script>
import {inject} from 'vue';
import {isEmpty,getFullImageUrl} from "@/utils/util";
import HotelSearch from "@/views/hotel/Search";
import {getTopHotels} from "@/services/hotel";
import {StarFilled} from '@ant-design/icons-vue';

export default {
  name: "HotelIndex",
  components: {HotelSearch, StarFilled},
  beforeMount() {
    this.selectedMenuKeys = ['hotel_index']
    this.getTopHotels()
  },
  computed: {
    isZHCN() {
      return this.$store.getters["setting/getLanguage"] === 'zh_CN'
    },
  },
  data() {
    return {
      selectedMenuKeys: inject('selectedMenuKeys'),
      language: this.$store.getters["setting/getLanguage"],
      topHotels: [],
      selectTopCity: '',
      coverImageStyle: {height: '100%', maxHeight: '392px'}
    }
  },
  methods: {
    isEmpty,getFullImageUrl,
    searchGo(searchData) {
      if (searchData.HotelId > 0)
        this.$router.push({name: 'hotel_detail', params: { HotelId: searchData.HotelId }, query: {
            CheckIn: searchData.CheckIn,
            CheckOut: searchData.CheckOut,
            PaxRoom: JSON.stringify(searchData.PaxRoom),
          }})
      else
        this.$router.push({
          name: 'hotel_list', params: { CityCode: searchData.CityCode }, query: {
            CheckIn: searchData.CheckIn,
            CheckOut: searchData.CheckOut,
            PaxRoom: JSON.stringify(searchData.PaxRoom),
            SubCityCode: searchData.SubCityCode
          }
        })
    },
    getTopHotels() {
      getTopHotels().then(res => {
        const resData = res.data
        if (resData.Success) {
          this.topHotels = resData.Data
          if(this.topHotels.length > 0)
            this.selectTopCity = this.topHotels[0].city_code
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
      })
    },
    goDetail(hotel_id){
      this.$router.push({
        name: 'hotel_detail', params: { HotelId: hotel_id }
      })
    }
  }
}
</script>

<style scoped>
.top-hotels .ant-radio-group {
  margin-right: 20px;
}

.top-hotels .hotels {
  margin-top: 20px;
}

.top-hotels .hotel {
  height: 280px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.1);
}

.top-hotels .hotel a {
  display: block;
  width: 100%;
  height: 100%;
}

.top-hotels .hotel .hotel-text {
  position: absolute;
  bottom: 8px;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  color: #FFFFFF;
  width: 96%;
  width: -moz-calc(100% - 16px);
  width: -webkit-calc(100% - 16px);
  width: calc(100% - 16px);
}

.top-hotels .hotel .starColor {
  color: #FFBB00;
  font-size: 20px;
  margin-right: 5px;
}
</style>